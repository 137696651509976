<template>
  <div class="members-chamber">
    <filter-convocation-council :member="true" @set-convocation="(e) => $emit('set-convocation', e)"/>
    <ul v-for="(info, i) in getMemberUnit"
        v-show="info.title !== 'Без должности'"
        :key="`getMemberUnit.units${i}`"
        class="composition_list">
      <li>{{ info.title }}</li>
      <router-link v-for="(item, i) in info.units" :key="i"
                   :to="`/structure_list/${item.id}`"
                   v-show="item.title !== 'Без отдела'"
                   tag="li"
      >{{ item.title }}</router-link>
    </ul>
  </div>

</template>

<script>
import FilterConvocationCouncil from '@/components/memberProfile/FilterConvocationCouncil'
export default {
  name: 'Composition',
  props: {
    id: {
      type: String
    },
    numberId: {
      type: String
    }
  },
  components: {
    FilterConvocationCouncil
  },
  data () {
    return {
      link: '2'
    }
  },

  beforeCreate () {
    // this.$store.dispatch('setConvocation')
    // console.log('Запрос к сервису GetMemberUnitList передаёт id члена палаты')
    this.$store.dispatch('apiMemberUnits', [this.numberId])
  },
  computed: {
    getMemberUnit () {
      return this.$store.getters.GET_MEMBER_UNIT
    }
  },
  watch: {
    '$store.getters.GET_LANG' () {
      this.getData()
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('apiMemberUnits', [this.numberId])
    }
  }
}
</script>

<style scoped lang="scss">
  .members-chamber {

    &__title {
      display: flex;
      margin-bottom: 1.375rem;
      align-items: center;
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 1.27;
      color: #1F3245;

      * {
        font-size: 0.75rem;
        line-height: 1.50;
        padding: .5rem 0.75rem;
        color: #003E78;
        opacity: 0.6;
      }

      .active_item {
        background: #EEF4FA;
        border-radius: 3px;
      }

      .lists {
        padding-left: 1.375em;
        display: flex;
        align-items: center;
      }

      .lists::after {
        content: '';
        margin-left: 0.75rem;
        width: 0.7rem;
        height: 0.7rem;
        background-image: url('../../assets/icons/icon_link.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }

    }
  }

  .composition_list {
    li {
      font-size: 0.875rem;
      line-height: 1.50;
      color: #246CB7;
      margin: 0.75rem 0 0.75rem 1.1rem;
      list-style-type: disc;
      cursor: pointer;
    }

    li:first-child {
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.57;
      color: rgba(31, 50, 69, 0.8);
      display: flex;
      align-items: center;
      margin: 0 0 1.25rem 0;
    }

    li:last-child {
      margin-bottom: 1.375rem;
    }
  }

  @media screen and (max-width: 768px){
    .members-chamber {
      &__title {
        .active_item {
          width: 21.27rem;
        }
      }
    }
  }
  @media screen and (max-width: 420px){
    .members-chamber{
      &__title{
        flex-direction: column;
        align-items: flex-start;
        .active_item {
          width: 100%;
        }
        .lists{
          padding: 1.38rem 0 0 0;
        }
      }
    }
  }
</style>
