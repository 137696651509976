import { render, staticRenderFns } from "./ChamberMemberProfile.vue?vue&type=template&id=7ed2c94c&scoped=true&"
import script from "./ChamberMemberProfile.vue?vue&type=script&lang=js&"
export * from "./ChamberMemberProfile.vue?vue&type=script&lang=js&"
import style0 from "./ChamberMemberProfile.vue?vue&type=style&index=0&id=7ed2c94c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ed2c94c",
  null
  
)

export default component.exports