import { render, staticRenderFns } from "./Composition.vue?vue&type=template&id=561ed720&scoped=true&"
import script from "./Composition.vue?vue&type=script&lang=js&"
export * from "./Composition.vue?vue&type=script&lang=js&"
import style0 from "./Composition.vue?vue&type=style&index=0&id=561ed720&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "561ed720",
  null
  
)

export default component.exports