<template>
  <div>
    <section class="biography-block" :class="{'open' : isDetails}">
      <div class="ckEditor-html" :class="{'active' : !isDetails}" v-html="getMemberInfo.biography"></div>
    </section>
    <div class="biography-details" @click="clickDetails">
      <i class="biography-details__arrow" :class="{'active' : isDetails}"></i>
     <span>{{`${isDetails ? 'Свернуть' : 'Развернуть'}`}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Biography',
  props: {
    id: {
      type: String
    }
  },
  data () {
    return {
      isDetails: false,
      trimLine: 8,
      biography: '<p>Родился 9 сентября 1958 года. В 1982 г. закончил Одесский политехнический институт.</p>' +
        '<p>С 1996 года до 1998 года — помощник депутата Верховной Рады Автономной Республики Крым и одновременно — заместитель председателя правления Крымского республиканского общества болгар им. П. Хилендарского.</p>' +
        '<p>С 2001 года и до настоящего времени — председатель правления Крымского республиканского общества болгар им. П. Хилендарского. С 2001 года и до настоящего времени — председатель правления Крымского республиканского общества болгар им. П. Хилендарского.</p>' +
        '<p>С 2001 года и до настоящего времени — председатель правления Крымского республиканского общества болгар им. П. Хилендарского.</p>'
    }
  },
  computed: {
    getMemberInfo () {
      return this.$store.getters.GET_MEMBER
    }
  },
  methods: {
    clickDetails () {
      this.isDetails = !this.isDetails
      // this.isDetails ? this.trimLine = 10 : this.trimLine = 8
      // bind (el, binding) {
      //   el.style.overflow = 'hidden'
      //   el.style.display = '-webkit-box'
      //   el.style.webkitLineClamp = binding.value
      //   el.style.webkitBoxOrient = 'vertical'
      // }
    }
  }
}
</script>

<style scoped lang="scss">
  %font-biography {
    font-style: normal;
    font-weight: normal;
    font-size: .875rem;
    line-height: 1.57;
  }
.ckEditor-html {
  display: flex;
  flex-direction: column;
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
    img{
      height: auto !important;
    }
    :nth-child(n) {
      margin: 0 0 1.4rem 0;
    }
  }
}
  .biography-block {
    /*overflow: hidden;*/
    /*display: -webkit-box;*/
    /*-webkit-line-clamp: 5;*/
    /*-webkit-box-orient: vertical;*/
    @extend %font-biography;
    color: rgba(23, 23, 23, 0.8);
    width: 80%;
    white-space: pre-wrap;
    height: 12rem;
    overflow: hidden;
    margin:0;
    .active:before {
      content:"";
      display:block;
      height: 1.25rem;
      position:relative;
      top: 12rem;
      background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 85%);
      margin-top: -1.25rem;
    }
    &.open {
      height: auto;
    }

  }

  .biography-details {
    @extend %font-biography;
    color: #246CB7;
    vertical-align: baseline;
    cursor: pointer;

    & i {
      border: solid #5A79A8;
      border-width: 0 .155rem .155rem 0;
      border-radius: .0625rem;
      display: inline-block;
      padding: .1875rem;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-bottom: .0625rem;
    }

    .active {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      margin: 0;
    }
    span {
      margin-left: .625rem;
    }

  }
  @media screen and (max-width: 768px){
    .biography-block {
      width: 97.3%;
    }
  }
  @media screen and (max-width: 420px){
    .biography-block {
      width: 100%;
    }
  }
</style>
