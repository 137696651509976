<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>
    <div class="two-rows-wrapper">
      <main>
        <div class="main__member-card">
          <figure v-if="getMemberInfo && getMemberInfo.photo">
            <img :src="`${this.$store.getters.getUrlApi}${getMemberInfo.photo}`" :alt="getMemberInfo.fullName">
          </figure>
          <article>
            <header>
              <h1 v-if="getMemberInfo && getMemberInfo.lastName">{{ `${getMemberInfo.lastName} ${getMemberInfo.firstName} ${getMemberInfo.patronymic || '' }` }}</h1>
              <!--              <h1 v-else >{{ `${getMemberInfo.lastNameEng} ${getMemberInfo.firstNameEng} ${getMemberInfo.patronymicEng || '' }` }}</h1>-->
            </header>
            <!--            <p>{{ toLocateMonth(getMemberInfo.dateOfBirth) }}</p>-->
            <div class="main__member-card__outside-job">
              <p v-for="(outsideJob, i) in getMemberInfo.outsideJob" :key="i">{{ outsideJob }}</p>
            </div>
            <div class="link_blue">
              <router-link :to="{ path: '/members_chamber', query: { plan: getMemberInfo.inclusionGround }}" >{{ getMemberInfo.inclusionGround }}</router-link>
            </div>
          </article>
        </div>

        <div class="main__composition" v-for="(nav, attribute, index) in anchorNavigation" :key="index"
             :id="`nav_${index}`">
          <header>
            <h2>{{ nav }}</h2>
          </header>
          <component :is="attribute" v-if="attribute !== 'composition' || $store.getters.GET_MEMBER && $store.getters.GET_MEMBER.id" :number-id="$store.getters.GET_MEMBER && $store.getters.GET_MEMBER.id" :id="idUser" @getPaginatedNews="getPaginatedNews()" @set-convocation="(e) => setConvocation(e)" @getPaginatedBlogs="getPaginatedBlogs($event)"/>
        </div>
        <br>
        <router-link v-if="(meId === idUser)" :to="`/blog?idUser=${idUser}`" link="/"
                         class="btn-blue">{{ $store.getters.GET_LANG ? 'Добавить блог' : 'Added blog' }}</router-link>

      </main>
      <aside class="two-rows-navigation">
        <h2>{{ $store.getters.GET_LANG ? 'Навигация по странице' : 'Page navigation' }}</h2>
        <nav>
          <a v-for="(nav, i, index) in anchorNavigation" :key="index" :href="`#nav_${index}`">{{ nav }}</a>
          <!--          <router-link v-for="(nav, i, index) in anchorNavigation" :key="index" :to="{ path: '/', hash: `nav_${index}` }">{{ nav }}</router-link>-->
        </nav>
      </aside>
    </div>

  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import Composition from '@/components/memberProfile/Composition'
import News from '@/components/memberProfile/News'
import Blog from '@/components/memberProfile/Blog'
import Biography from '@/components/memberProfile/Biography'
import currentYear from '@/mixins/currentYear'

export default {
  name: 'MemberProfile',
  mixins: [currentYear],
  props: {
    memberId: {
      type: Number,
      default: 1
    }
  },
  components: {
    BreadCrumb,
    Composition,
    News,
    Blog,
    Biography
  },
  data: function () {
    return {
      convocation: null,
      // idUserInStorage: (localStorage.getItem('token') !== null ? Number(atob(localStorage.getItem('token').split('{==}')[0]).split(';')[0]) : -1),
      isToken: localStorage.getItem('token') !== null ? 'true' : false,
      meId: '',
      idUser: this.$route.params.id,
      afterAuth: false,
      anchorNavigation: {
        composition: this.$store.getters.GET_LANG ? 'Состав' : 'Composition',
        biography: this.$store.getters.GET_LANG ? 'Биография' : 'Biography',
        news: this.$store.getters.GET_LANG ? 'Новости' : 'News',
        blog: this.$store.getters.GET_LANG ? 'Блог' : 'The blog'
      }
    }
  },

  beforeMount () {
    this.$store.commit('setPaginationLimit', 3)
    this.getData()
  },

  computed: {
    getMemberInfo () {
      return this.$store.getters.GET_MEMBER
    },
    getUserMe () {
      return this.$store.getters.user
    },
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'О палате' : 'About',
          path: '/about_the_chamber/4'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Члены Общественной палаты' : 'Members of chamber'
        }
      ]
    }
  },
  watch: {
    '$store.getters.GET_LANG' () {
      this.getData()
    },
    'convocation.id' () {
      this.$store.dispatch('getPublicNews', { convocationId: this.convocation.id, personTag: `${this.getMemberInfo.firstName} ${this.getMemberInfo.lastName}` })
    },
    getMemberInfo (value) {
      document.title = value.fullName
      this.$store.commit('setPaginationLimit', 3)
      if (Object.keys(this.$store.getters.GET_MEMBER)?.length > 0) {
        this.$store.commit('setFilterWithActual', true)
        this.$store.dispatch('getPublicNews', { convocationId: this.convocation.id, personTag: `${this.getMemberInfo.firstName} ${this.getMemberInfo.lastName}` })
        this.$store.commit('setFilterTag', null)
        this.$store.commit('setFilterTagEng', null)
        this.$store.commit('setFilterWithActual', null)
      }
      if (!this.getMemberInfo.biography) {
        this.deleteAnchorNavigation('biography')
      }
    },
    getUserMe () {
      this.meId = this.$store.getters.user.member_slug
      if (!this.afterAuth) {
        this.afterAuth = true
        this.getData()
      }
    },
    // '$store.getters.getPaginationTotal' () {
    //   console.log(this.$store.getters.getPaginationTotal)
    //   if (this.$store.getters.getPaginationTotal === 0) {
    //     this.deleteAnchorNavigation('news')
    //   } else {
    //     if (!('news' in this.anchorNavigation)) {
    //       this.anchorNavigation.news = this.$store.getters.GET_LANG ? 'Новости' : 'News'
    //       this.$forceUpdate()
    //     }
    //   }
    // },
    '$store.getters.GET_LIST_POSTS' () {
      const itemPosts = this.$store.getters.GET_LIST_POSTS
      if ('count' in itemPosts ? itemPosts.count === 0 : '' || itemPosts.length) {
        this.deleteAnchorNavigation('blog')
      }
    }
  },
  beforeDestroy () {
    this.$store.commit('SET_MEMBER', {})
  },
  methods: {
    setConvocation (e) {
      this.convocation = e
    },
    getPaginatedBlogs (page) {
      this.$store.dispatch('apiMemberPost', {
        id: this.idUser,
        isPublic: this.meId !== this.idUser,
        offset: (page - 1) * 3,
        limit: 3
      })
    },
    getPaginatedNews () {
      this.$store.commit('setFilterWithActual', true)
      this.$store.dispatch('getPublicNews', { convocationId: this.convocation.id, personTag: `${this.getMemberInfo.firstName} ${this.getMemberInfo.lastName}` })
      this.$store.commit('setFilterTag', null)
      this.$store.commit('setFilterWithActual', null)
    },
    getData () {
      // получаем посты блога
      this.$store.dispatch('apiMemberPost', {
        id: this.idUser,
        isPublic: this.meId !== this.idUser,
        offset: 0,
        limit: 3
      })
      this.anchorNavigation.composition = this.$store.getters.GET_LANG ? 'Состав' : 'Composition'
      this.anchorNavigation.biography = this.$store.getters.GET_LANG ? 'Биография' : 'Biography'
      // this.anchorNavigation.news = this.$store.getters.GET_LANG ? 'Новости' : 'News'
      this.anchorNavigation.blog = this.$store.getters.GET_LANG ? 'Блог' : 'The blog'
      // this.$store.dispatch('apiMemberList', { year: this.getCurrentYear() }) // from mixin
      this.$store.dispatch('apiMemberList', {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        },
        year: this.getCurrentYear()
      })
      console.log('Запрос к сервису GetMember передаёт id члена палаты')
      this.$store.dispatch('apiMember', this.idUser)
      if (this.isToken) {
        return this.$store.dispatch('fetchUser')
      }
    },
    toLocateMonth (date) {
      return (new Date(date).toLocaleString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })).slice(0, -3)
    },
    deleteAnchorNavigation (name) {
      delete this.anchorNavigation[name]
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="scss">

  .main__member-card {
    display: flex;

    figure {
      img {
        border-radius: 8px;
        max-width: 13rem;
        height: auto;
      }
    }

    article {
      margin-left: 1.875rem;
      text-align: left;
      font-size: .9rem;
      line-height: 1.375;
      font-weight: normal;

      p {
        color: #5E686C;
      }
    }

    &__outside-job {
      margin-top: 1.9rem;
    }
  }

  .link_blue {
    margin-top: 1.9rem;
    color: #246CB7;
  }

  h1 {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.27;
    color: #1F3245;
    margin-bottom: .8125rem;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.33;
    color: rgba(31, 50, 69, 0.8);
  }

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.375rem;
  }

  .main__composition {
    margin-top: 3.125rem;
  }

  @media screen and (max-width: 768px) {
    .main__member-card {
      figure img {
        max-height: 12.59rem;
      }
    }
  }

  @media screen and (max-width: 420px) {
    .main__member-card {
      flex-direction: column;

      figure img {
        max-height: 10.81rem;
      }

      article {
        margin-left: 0;
        margin-top: 1.13rem;
      }
    }
  }
</style>
