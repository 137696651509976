/* Принимает параметром 'member' условие - подключени ли со страницы member_profile */
<template>
  <div class="select_block">
    <custom-select :key="`inclusion${count}`" class="select"
                   :list='optionsSelect'
                   :default='1'
                   :placeholder="`${titleActive}`"
                   @InputsContent="setInclusion"/>
    <a href="https://old.oprf.ru/ru/about/chambermembers/members2020/" target="_blank" class="lists">
      {{ $store.getters.GET_LANG ? 'Составы до 2020 года' : 'Lineups until 2020' }}
    </a>
  </div>
</template>

<script>
import CustomSelect from '@/components/forms/CustomSelect'

export default {
  name: 'FilterConvocationCouncil',
  props: {
    member: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CustomSelect
  },
  data () {
    return {
      count: 0,
      selectedYear: 2023,
      optionsSelect: [],
      titleActive: '1'
    }
  },
  // beforeCreate () {
  //   // console.log('Запрос к сервису GetMemberUnitList передаёт id члена палаты')
  //   this.$store.dispatch('apiMemberUnits', this.$route.params.id)
  // },
  computed: {
    GET_CONVOCATION_LIST () {
      return this.$store.getters.GET_CONVOCATION_LIST
    },
    GET_UNIT () {
      return this.$store.getters.GET_UNIT
    }
  },
  mounted () {
    this.$store.dispatch('apiGetConvocationList')
    this.$store.dispatch('apiGetUnit')
  },
  watch: {
    GET_CONVOCATION_LIST () {
      this.preparationConvocationForSelector()
      this.setInclusion(this.optionsSelect[1])
    },
    '$store.getters.GET_LANG' () {
      this.preparationConvocationForSelector()
    }
  },
  methods: {
    setInclusion (value) {
      const convocationSelect = this.$store.getters.GET_CONVOCATION_LIST
        .filter(item => {
          return item.id === value.id
        })[0]
      this.selectedYear = convocationSelect.startPeriod
      this.$emit('update-year', convocationSelect.startPeriod)
      this.$emit('set-convocation', convocationSelect)
      this.member
        ? this.$store.dispatch('apiMemberUnits', [this.$store.getters.GET_MEMBER?.id, `?year=${this.selectedYear}`])
        : this.$store.dispatch('apiMemberList', { year: this.selectedYear }) /* TODO Пофиксить после тестов */
    },
    clickSelect () {
      this.showSelect = !this.showSelect
    },
    // Подготовка данных для Custom Select
    preparationConvocationForSelector () {
      const convocationRow = this.$store.getters.GET_CONVOCATION_LIST
      this.optionsSelect.length = 0
      const that = this
      this.$store.getters.GET_LANG ? convocationRow.forEach(item => {
        that.optionsSelect.push({
          title: item.name + ` (${item.startPeriod}–${item.endPeriod} гг.)`,
          id: item.id
        })
      })
        : convocationRow.forEach(item => {
          that.optionsSelect.push({
            title: item.nameEng + ` (${item.startPeriod}–${item.endPeriod})`,
            id: item.id
          })
        })
      this.titleActive = this.optionsSelect[0].title
    }
  }
}
</script>

<style lang="scss" scoped>
  .select_block {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.8125rem;

    > select {
      opacity: 0.6;
      width: 19.375rem;
    }

    a {
      font-size: .85rem;
      line-height: 1.50;
      padding: .5rem 0.75rem;
      color: #003E78;
      opacity: 0.6;
    }

    .lists {
      padding-left: 1.375em;
      display: flex;
      align-items: center;
    }

    .lists::after {
      content: '';
      margin-left: 0.75rem;
      width: 0.7rem;
      height: 0.7rem;
      background-image: url('../../assets/icons/icon_link.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
</style>
